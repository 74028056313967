import { ref } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios';
export const useFiltersSortingAndPagination = (data) => {
 
  if(!data){
    data = {};
  }

  const filters = ref({
    q: data.q || '',
    organization: data.organization || null,
    page: data.page? parseInt(data.page): 1,
    limit: data.limit ? parseInt(data.limit) : 10,
 
  })



  return {
    // Filter
    filters,
    // Sort

  }
}

export const useUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalVisualTemplates = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalVisualTemplates,
  }
}

export const useRemoteData = () => {
  const visualTemplates = ref([])
  const fetchVisualTemplates = (args) => {
    return new Promise((resolve, reject) => {
      console.log(args)
      axios
        .get('/visualTemplates', {params: args})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })


  }
  const deleteVisualTemplates = (args) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/visualTemplates/deletevisualTemplates', args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  return {
    visualTemplates,
    fetchVisualTemplates,
    deleteVisualTemplates
  }
}
