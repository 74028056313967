<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalVisualTemplates }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="showNewVisualTemplateModal()"
                >New Template</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="visualTemplate in curretVisualTemplates"
        :key="visualTemplate.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link class="text-body" target="_blank" :href="visualURL+visualTemplate.token" >
              {{ visualTemplate.name }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ visualTemplate.organization }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ visualTemplate.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="$refs['clone'].showModal(visualTemplate)"
          >
            <span>Clone</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-wishlist"
            @click="$refs['assign'].showModal([visualTemplate])"
          >
            <span>Assign</span>
          </b-button>
          <b-button
            variant="relief-warning"
            class="btn-wishlist"
            @click="showNewVisualTemplateModal(visualTemplate)"
          >
            <span>Edit</span>
          </b-button>
          <b-button
            variant="danger"
            tag="a"
            class="btn-cart"
            @click="deleteSelectedVisualTemplates([visualTemplate])"
          >
            <span>Delete</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalVisualTemplates"
            :per-page="filters.limit"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <portal to="content-renderer-sidebar-detached-left">
      <div>
        <visual-templates-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
      </div>
    </portal>
    <b-modal
      id="modal-prevent-closing"
      no-close-on-backdrop
      ref="new-visual-template-modal"
      :title="createOrUpdate + ' Template'"
      :ok-title="createOrUpdate"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetNewVisualTemplateModal"
      @ok="newVisualTemplateOk"
    >
      <form ref="form" @submit.stop.prevent="newVisualTemplateSubmit">
        <b-form-group
          :state="newVisualTemplateState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="newVisualTemplate.name"
            :state="newVisualTemplateState"
            required
          />
        </b-form-group>
        <b-form-group :state="newVisualTemplateState" label="Description">
          <b-form-textarea
            v-model="newVisualTemplate.description"
            :state="newVisualTemplateState"
            required
          />
        </b-form-group>
           <v-select v-model="newVisualTemplate.templateTypeId" :reduce="option => option.id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="value" :options="templateTypes" />
      </form>
    </b-modal>

    <assign-modal
      ref="assign"
      @on-assign="assignHandleSubmit"
      :organizations="filterOptions.organizations"
    ></assign-modal>
    <clone-modal ref="clone" @on-clone="cloneHandleSubmit"></clone-modal>

    <div id="contilt-temp"></div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BFormGroup,
  BFormTags,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import {
  useFiltersSortingAndPagination,
  useUi,
  useRemoteData,
} from "./useVisualTemplate";
import organizationStoreModule from "../../organization/organizationStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import visualTemplatesLeftFilterSidebar from "./VisualTemplatesLeftFilterSidebar.vue";
import axios from "@axios";
import AssignModal from "../../../modals/AssignModal";
import CloneModal from "../../../modals/CloneModal";
import router from "@/router";
import vSelect from 'vue-select';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BFormGroup,
    visualTemplatesLeftFilterSidebar,
    BFormTags,
    BFormTextarea,
    AssignModal,
    CloneModal,
    vSelect
  },
  data() {
    return {
      createOrUpdate: "Create",
      templateTypes: [],
      newVisualTemplate: {
        id: null,
        name: "",
        description: "",
        templateTypeId: null
      },
      newVisualTemplateState: null,
      filterOptions: {
        organizations: [],
      },
      toAssign: null,
      assignOrganizationsState: null,
    };
  },
  computed: {
    curretVisualTemplates() {
      return this.visualTemplates;
    },
    type() {
        return this.$route.query.type;
    },
    visualURL() {
      return process.env.VUE_APP_VISUAL_URL;
    }
  },
  methods: {
    assignHandleSubmit(data) {
      axios
        .post("/visualTemplates/assign", {
          visualTemplates: data.toAssign,
          destinationOrganization: data.destinationOrganization,
        })
        .then(() => this.fetchTheVisualTemplates())
        .catch((error) => console.log(error));
    },

    cloneHandleSubmit(data) {
      axios
        .post(`/visualTemplates/clone/${data.id}`, { name: data.name })
        .then(() => this.fetchTheVisualTemplates())
        .catch((error) => console.log(error));
    },
   addNewItm(itemListObject) {
      itemListObject.items.push(
        JSON.parse(JSON.stringify(itemListObject.defaultTemplate))
      );
    },
    showNewVisualTemplateModal(visualTemplate) {
      if(visualTemplate){
        this.createOrUpdate = "Update"
        this.newVisualTemplate = {...visualTemplate};
      }else {

        this.createOrUpdate = "Create"
        this.newVisualTemplate.id = null;
        this.newVisualTemplate.name = "";
        this.newVisualTemplate.description = "";
        this.newVisualTemplate.templateTypeId = null;
      }
     
      this.$refs["new-visual-template-modal"].show();
    },
    checkNewVisualTemplateFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.newVisualTemplateState = valid;
      return valid;
    },
    resetNewVisualTemplateModal() {
      this.newVisualTemplate = {
        name: "",
        description: "",
        templateTypeId: null
      };
      this.newVisualTemplateState = null;
    },
    newVisualTemplateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.newVisualTemplateSubmit();
      this.resetNewVisualTemplateModal();
    },
    newVisualTemplateSubmit() {
      debugger;
      // Exit when the form isn't valid
      if (!this.checkNewVisualTemplateFormValidity()) {
        return;
      }
      const data = JSON.parse(JSON.stringify(this.newVisualTemplate));
      if(this.createOrUpdate == "Update"){
        axios
          .patch(`/visualTemplates/${data.id}`, data)
          .then(() => {
            this.fetchTheVisualTemplates();
          })
          .catch((error) => console.log(error));
      }
      else {
        axios
          .post("/visualTemplates", data)
          .then(() => {
            this.fetchTheVisualTemplates();
          })
          .catch((error) => console.log(error));
      }


      this.$nextTick(() => {
        this.$refs["new-visual-template-modal"].toggle("#toggle-btn");
      });
    },

    deleteSelectedVisualTemplates(toDelete) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to delete the selected template types [${toDelete.map(
            (x) => x.name
          )}]?`,
          {
            title: "Delete Template Types",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteVisualTemplates(toDelete.map((x) => x.id)).then((res) => {
              this.fetchTheVisualTemplates();
              console.log(res);
            });
          }
        });
    },
  },
  created() {
    let _this = this;
    axios.get("/templateTypes", {params: {limit: 1000, type: this.type, selectedFields: "id name"}}).then(res => {
      this.templateTypes = res.data.results.map(x => {
        return {
          id: x.id,
          value: x.name
        }
      })
    })
    store
      .dispatch("app-organization/fetchOrganizations", {selectedFields: "id name", limit: 1000})
      .then((response) => {
        const organizations = response.data.results.map((x) => {
          return {
            id: x.id,
            value: x.name,
          };
        });

        _this.filterOptions.organizations = organizations;
        if (!_this.filters.organization) {
          _this.filters.organization = organizations[0].id;
        } else {
          this.fetchTheVisualTemplates();
        }
      });
  },
  setup() {
    function startLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "contents";
    }
    function stopLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "none";
    }

    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );

    const { filters } = useFiltersSortingAndPagination(
      router.currentRoute.query
    );

    const { itemView, itemViewOptions, totalVisualTemplates } = useUi();

    const { visualTemplates, fetchVisualTemplates, deleteVisualTemplates } =
      useRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const fetchTheVisualTemplates = () => {
      startLoading();
      const orgs = [];
      if (filters.value.organization) {
        orgs.push(filters.value.organization);
      }
      const query = {
        q: filters.value.q,
        organizations: orgs.join(","),
        page: filters.value.page,
        limit: filters.value.limit,
      };

      router.replace({
        params: router.currentRoute.params,
        query: { ...router.currentRoute.query, ...filters.value, ...query },
      });

      fetchVisualTemplates(query).then((response) => {
        stopLoading();
        visualTemplates.value = response.data.results;
        totalVisualTemplates.value = response.data.totalResults;
      });
    };

    watch(
      [filters],
      () => {
        fetchTheVisualTemplates();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,

      // useShopUi
      itemView,
      itemViewOptions,
      totalVisualTemplates,
      deleteVisualTemplates,
      // useShopRemoteData
      visualTemplates,
      fetchTheVisualTemplates,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
#contilt-temp {
  display: none;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
